import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cfdf9eee = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _c8a25f7e = () => interopDefault(import('../pages/agegate.vue' /* webpackChunkName: "pages/agegate" */))
const _0bd8a55a = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _2564ee8d = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _44f79761 = () => interopDefault(import('../pages/ganhadores.vue' /* webpackChunkName: "pages/ganhadores" */))
const _5bf5aa25 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _5e115471 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _181b5212 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _0873bfc0 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _568acc64 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _65f68293 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _e7f9cc6a = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _d3a9ef78 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _b96888fc = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _d5edf28e = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _0a80f550 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _22b46f3a = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _3ac11193 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _1a228742 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _733c1ee6 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _66a2de3e = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _7220417d = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _22453954 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _3da91183 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _2efc077c = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _39a3af3e = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _f1fe51c8 = () => interopDefault(import('../pages/admin/tickets-check/index.vue' /* webpackChunkName: "pages/admin/tickets-check/index" */))
const _63105536 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _dcde3250 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _384573fc = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _47cf654e = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _3557330e = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _9450f83e = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _769f011b = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _605a843e = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _06e361e1 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _059f631b = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _07747487 = () => interopDefault(import('../pages/admin/auth/update.vue' /* webpackChunkName: "pages/admin/auth/update" */))
const _56ea4afa = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _24400108 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _6d51ff6e = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _c1a52cca = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _26ae9c48 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _81007608 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _26269936 = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _1145d289 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _00f9bf10 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _4d192f96 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _446533a7 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _6d0ec694 = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _7b906496 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _4822c5de = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _7747ab1f = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _76a64bac = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _387c5cbb = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _b0651bac = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _84cc864c = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _e24b01b6 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _8a8cfe88 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _33669320 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _3b55c278 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _cfdf9eee,
    name: "admin"
  }, {
    path: "/agegate",
    component: _c8a25f7e,
    name: "agegate"
  }, {
    path: "/cupom",
    component: _0bd8a55a,
    name: "cupom"
  }, {
    path: "/faq",
    component: _2564ee8d,
    name: "faq"
  }, {
    path: "/ganhadores",
    component: _44f79761,
    name: "ganhadores"
  }, {
    path: "/perfil",
    component: _5bf5aa25,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _5e115471,
    name: "regulamentos"
  }, {
    path: "/admin/actions",
    component: _181b5212,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _0873bfc0,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _568acc64,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _65f68293,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _e7f9cc6a,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _d3a9ef78,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _b96888fc,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _d5edf28e,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _0a80f550,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _22b46f3a,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _3ac11193,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _1a228742,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _733c1ee6,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _66a2de3e,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _7220417d,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _22453954,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _3da91183,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _2efc077c,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _39a3af3e,
    name: "admin-tickets"
  }, {
    path: "/admin/tickets-check",
    component: _f1fe51c8,
    name: "admin-tickets-check"
  }, {
    path: "/admin/tokens",
    component: _63105536,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _dcde3250,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _384573fc,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _47cf654e,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _3557330e,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _9450f83e,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _769f011b,
    name: "auth-login"
  }, {
    path: "/register",
    component: _605a843e,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _06e361e1,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _059f631b,
    name: "admin-auth-login"
  }, {
    path: "/admin/update",
    component: _07747487,
    name: "admin-auth-update"
  }, {
    path: "/admin/dispatches/create",
    component: _56ea4afa,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _24400108,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _6d51ff6e,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _c1a52cca,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _26ae9c48,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _81007608,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _26269936,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _1145d289,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _00f9bf10,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _4d192f96,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _446533a7,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _6d0ec694,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _7b906496,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _4822c5de,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _7747ab1f,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _76a64bac,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _387c5cbb,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _b0651bac,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _84cc864c,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _e24b01b6,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _8a8cfe88,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _33669320,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _3b55c278,
    name: "auth-reset-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
